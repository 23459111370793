import React, { Component } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { withTranslation } from "react-i18next"
import { Container } from "styled-bootstrap-grid"
import styled from "styled-components"
import colors from "../styles/colors"
import fonts from "../styles/fonts"


const PageContainer = styled(Container)`
    padding: 200px 0;
    text-align: center;
`

const Content = styled.p`
    font-size: 20px;
    margin-left: 33px;
    margin-right: 33px;
`

const Error = styled.div`
    color: ${colors.blue};
    ${fonts.jaggerRegular};
    font-size: 100px;
    padding-bottom: 50px;
    padding-top: 50px;
`

class NotFoundPage extends Component {
  render() {
    const { t, i18n } = this.props

    return (
      <Layout meta={{
        lang: i18n.language === 'en' ? 'en-us' : 'fr-fr',
        alternateLanguages:[{ lang: i18n.language === 'en' ? 'fr-fr': 'en-us' }],
      }}>
        <SEO title={t("404.title")}
             description={t("404.description")}/>
        <PageContainer>
          <Content>Ooops!</Content>
          <Error>404</Error>
          <Content>{t("404.content")}</Content>
        </PageContainer>
      </Layout>
    )
  }
}

export default withTranslation()(NotFoundPage)

